<template>
  <div class="component">
    <div
      class="row justify-content-between align-items-start"
      style="padding: 0 1.5rem"
    >
      <div>
        <div class="mb-1">
          <b-button
            variant="outline-primary"
            class="mr-1"
            :to="'/' + listingOption.class.toLowerCase() + '/create/' + $route.params.id"
          >
            <!-- @click="$router.push({ name: 'create-commercial' })" -->
            <feather-icon icon="PlusIcon" />
            Add New {{ listingOption.class }} Listing
          </b-button>
          <b-button
            v-if="listingOption.class === 'Residential'"
            v-b-modal.modal-import
            variant="outline-secondary"
          >
            Import {{ listingOption.class }} CSV
          </b-button>
        </div>
        <div class="d-flex mb-1 align-items-end justify-content-between">
          <div>
            <b-dropdown
              id="dropdown-1"
              :text="'Sort by: ' + getSelectedSortOption"
              variant="outline-primary"
            >
              <b-dropdown-item
                :variant="selectedOrderBy === 'price_asc' ? 'primary' : ''"
                @click="onSortItemClick('price_asc')"
              >
                <div class="d-flex align-items-center justify-content-between">
                  Price: Lowest to Highest
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                :variant="selectedOrderBy === 'price_desc' ? 'primary' : ''"
                @click="onSortItemClick('price_desc')"
              >
                <div class="d-flex align-items-center justify-content-between">
                  Price: Highest to Lowest
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                :variant="selectedOrderBy === 'date_desc' ? 'primary' : ''"
                @click="onSortItemClick('date_desc')"
              >
                <div class="d-flex align-items-center justify-content-between">
                  List Date: Newest to Oldest
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                :variant="selectedOrderBy === 'date_asc' ? 'primary' : ''"
                @click="onSortItemClick('date_asc')"
              >
                <div class="d-flex align-items-center justify-content-between">
                  List Date: Oldest to Newest
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                :variant="selectedSource === 'RETS' ? 'primary' : ''"
                @click="onSortBySource('RETS')"
              >
                <div class="d-flex align-items-center justify-content-between">
                  Only RETS
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                :variant="selectedSource === 'Exclusive' ? 'primary' : ''"
                @click="onSortBySource('Exclusive')"
              >
                <div class="d-flex align-items-center justify-content-between">
                  Only Exclusive
                </div>
              </b-dropdown-item>
            </b-dropdown>
            <b-button
              variant="outline-danger"
              class="btn-icon ml-1"
              @click="onListingDeleteClick"
            >
              <!-- @click="onListingDeleteClick" -->
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
          <!-- <div class="d-flex flex-column align-items-end">
              <span>{{ getTotalNumofListings }}</span>
              <span>{{ getNumOfListings }}</span>
            </div> -->
        </div>
      </div>
      <b-input-group
        class="input-group-merge mb-1"
        style="max-width: 300px; float: right"
      >
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input
          v-model="search"
          placeholder="Search by address or MLS Id"
        />
        <!-- v-model="search" -->
        <!-- @input="onSearch" -->
      </b-input-group>
    </div>
    <b-tabs>
      <b-tabs>
        <b-tab
          title="Active"
          @click="onStatusClick('Active')"
        />
        <b-tab
          title="Sold"
          @click="onStatusClick('Sold')"
        />
        <b-tab
          title="Expired"
          @click="onStatusClick('Expired')"
        />
        <b-tab
          title="Termianted"
          @click="onStatusClick('Terminated')"
        />
      </b-tabs>
      <b-tab
        title="Residential"
        @click="onClassClick('Residential')"
      >
        <div
          v-if="getListings.length && listingOption.class === 'Residential'"
          class="row"
        >
          <div
            v-for="(singleListing, index) in getListings"
            :key="'residential' + index"
            class="singleListing col-lg-4 col-md-6 col-sm-12 col-xs-12"
          >
            <ListingCard
              :listing="singleListing"
              path="residential"
              @on-delete-listing="onDeleteListing"
              @on-checkbox-update="onCheckBoxChanged"
            />
          </div>
        </div>

        <div v-else>
          <b-alert
            variant="warning"
            show
          >
            <div class="alert-body">
              <feather-icon
                class="mr-1"
                icon="AlertOctagonIcon"
              />
              No Listing Data
            </div>
          </b-alert>
        </div>
      </b-tab>

      <b-tab
        title="Commercial"
        @click="onClassClick('Commercial')"
      >
        <div
          v-if="getListings.length && listingOption.class === 'Commercial'"
          class="row"
        >
          <div
            v-for="(singleListing, index) in getListings"
            :key="'commercial' + index"
            class="singleListing col-lg-4 col-md-6 col-sm-12 col-xs-12"
          >
            <ListingCard
              :listing="singleListing"
              path="commercial"
              @on-delete-listing="onDeleteListing"
              @on-checkbox-update="onCheckBoxChanged"
            />
          </div>
        </div>

        <div v-else>
          <b-alert
            variant="warning"
            show
          >
            <div class="alert-body">
              <feather-icon
                class="mr-1"
                icon="AlertOctagonIcon"
              />
              No Listing Data
            </div>
          </b-alert>
        </div>
      </b-tab>

      <b-tab
        title="Rental"
        @click="onClassClick('Rental')"
      >
        <div
          v-if="getListings.length && listingOption.class === 'Rental'"
          class="row"
        >
          <div
            v-for="(singleListing, index) in getListings"
            :key="'rental' + index"
            class="singleListing col-lg-4 col-md-6 col-sm-12 col-xs-12"
          >
            <ListingCard
              :listing="singleListing"
              path="rental"
              @on-delete-listing="onDeleteListing"
              @on-checkbox-update="onCheckBoxChanged"
            />
          </div>
        </div>

        <div v-else>
          <b-alert
            variant="warning"
            show
          >
            <div class="alert-body">
              <feather-icon
                class="mr-1"
                icon="AlertOctagonIcon"
              />
              No Listing Data
            </div>
          </b-alert>
        </div>
      </b-tab>
    </b-tabs>

    <b-modal
      id="modal-import"
      cancel-variant="outline-secondary"
      ok-title="Import"
      cancel-title="Close"
      centered
      title="Import Listings from CSV file"
      @ok="importCsv"
    >
      <b-form-file
        v-model="importFile"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BAlert,
  BDropdown,
  BDropdownItem,
  BModal,
  BFormFile,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ListingCard from '../../../../Listings/components/listingCard.vue'

export default {
  components: {
    BAlert,
    BTabs,
    BTab,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BModal,
    BFormFile,
    ListingCard,
  },
  data() {
    return {
      listingOption: {
        class: 'Residential',
        status: 'Active',
      },
      listings: [],
      importFile: [],
      csvData: [],
      targetToDelete: [],
      selectedOrderBy: 'date_desc',
      selectedSource: '',
      search: '',
    }
  },
  computed: {
    getListings() {
      let listingsData = this.listings
      if (this.selectedOrderBy === 'price_desc') {
        listingsData = listingsData.sort((a, b) => b.asking_price - a.asking_price)
      }

      if (this.selectedOrderBy === 'price_asc') {
        listingsData = listingsData.sort((a, b) => a.asking_price - b.asking_price)
      }

      if (this.selectedOrderBy === 'date_desc') {
        listingsData = listingsData.sort((a, b) => new Date(b.list_date) - new Date(a.list_date))
      }

      if (this.selectedOrderBy === 'date_asc') {
        listingsData = listingsData.sort((a, b) => new Date(a.list_date) - new Date(b.list_date))
      }

      if (this.search !== '') {
        listingsData = listingsData.filter(data => (data.mls_id !== null ? data.mls_id.includes(this.search) || data.address.toLowerCase().includes(this.search.toLowerCase()) : data.address.toLowerCase().includes(this.search.toLowerCase())))

        if (this.selectedSource !== '') {
          listingsData = listingsData.filter(data => data.source === this.selectedSource)
        }
      }

      if (this.selectedSource) {
        listingsData = listingsData.filter(data => data.source === this.selectedSource)
      }

      console.log('get listing', listingsData)

      return listingsData
    },
    getSelectedSortOption() {
      let current = ''
      switch (this.selectedOrderBy) {
        case 'price_asc':
          current = 'Price Lowest to Highest'
          break
        case 'price_desc':
          current = 'Price Highest to Lowest'
          break
        case 'date_asc':
          current = 'Date Oldeset to Newest'
          break
        case 'date_desc':
          current = 'Date Newest to Oldest'
          break
        default:
          break
      }

      if (this.selectedSource !== '') {
        current += ' / ' + this.selectedSource
      }

      return current
    },
    getCsvListedDate(date) {
      const dateArr = date.split('/')

      const month = dateArr[0].length === 1 ? '0' + dateArr[0] : dateArr[0]
      const day = dateArr[1].length === 1 ? '0' + dateArr[1] : dateArr[1]
      const year = dateArr[2]

      return year + '-' + month + '-' + day
    },
    getStatus(status) {
      let converted
      switch (status) {
        case 'A':
          converted = 'Active'
          break
        case 'S':
          converted = 'Sold'
          break
        case 'E':
          converted = 'Expired'
          break
        case 'T':
          converted = 'Terminated'
          break
        default:
          converted = 'Active'
          break
      }

      return converted
    },
    removeComma(val) {
      // eslint-disable-next-line comma-spacing
      return val.replace(/,/g,'')
    },
    convertToIntFormat(price) {
      let intPrice = price.substring(1)
      // eslint-disable-next-line comma-spacing
      intPrice = intPrice.replace(/,/g,'')
      return intPrice
    },
  },
  created() {
    this.loadListings()
  },
  methods: {
    onStatusClick(status) {
      this.listingOption.status = status
      this.loadListings()
    },
    onClassClick(listingClass) {
      this.listingOption.class = listingClass
      this.loadListings()
    },
    onSortItemClick(sortOpt) {
      this.selectedOrderBy = sortOpt
    },
    onSortBySource(sortOpt) {
      if (this.selectedSource !== sortOpt) {
        this.selectedSource = sortOpt
      } else {
        this.selectedSource = ''
      }
    },
    onListingDeleteClick() {
      if (this.targetToDelete.length > 0) {
        console.log(this.targetToDelete)
        const data = {
          account_id: this.$route.params.id,
          options: this.targetToDelete,
        }

        this.$store.dispatch('superadmin/deleteListings', data).then(response => {
          console.log(response)
          if (response.code === 200) {
            // if (response.data.length) {
            //   response.data.length.forEach
            // }

            response.data.forEach(listing => {
              const targetId = listing.system_id

              this.listings = this.listings.filter(deleted => deleted.system_id !== null && deleted.system_id !== targetId)
            })
          }
        }).catch(err => {
          console.error(err)
        })
      } else {
        this.showToast('Failed', 'AlertCircleIcon', 'Listing should be selected at least one', 'danger', 'bottom-right')
      }
    },
    onDeleteListing(targetListing) {
      const targetId = targetListing.system_id

      this.listings = this.listings.filter(data => data.system_id !== null && data.system_id !== targetId)
    },
    onCheckBoxChanged(data) {
      console.log(data)
      // targetToDelete
      const { system_id, isSelected, type } = data

      if (isSelected) {
        this.targetToDelete.push({ system_id, type })
      } else {
        // eslint-disable-next-line camelcase
        const targetIndex = this.targetToDelete.findIndex(i => i.system_id === system_id)
        this.targetToDelete.splice(targetIndex, 1)
      }
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
    loadListings() {
      this.$store
        .dispatch('superadmin/getListing', {
          id: this.$route.params.id,
          options: this.listingOption,
        })
        .then(response => {
          console.log(response)
          if (response.code === 200) {
            this.listings = response.data
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    importCsv() {
      const vm = this
      vm.csvData = []
      const reader = new FileReader()
      reader.onload = e => {
        vm.file = vm.csvJSON(e.target.result)
      }
      reader.readAsText(this.importFile)
    },
    csvJSON(csv) {
      const line = csv.split('\n')
      const header = line[0].split('","')
      line.shift()

      line.forEach(element => {
        if (element) {
          const columns = element.split('","')
          const temp = {}
          columns.forEach((col, index) => {
            let label = header[index].replace(/['"]+/g, '')
            label = label.replace(/\s/g, '_')
            const val = col.replace(/['"]+/g, '')

            switch (label) {
              case 'Address':
                label = 'address'
                temp[label] = val
                break
              case 'Area':
                label = 'area'
                temp[label] = val
                break
              case 'City':
                label = 'city'
                temp[label] = val
                break
              case 'Floor_Area_-Grand_Total':
                label = 'floor_size'
                temp[label] = this.removeComma(val)
                break
              case 'List_Date':
                label = 'list_date'
                temp[label] = this.getCsvListedDate(val)
                break
              case 'List_Price':
                label = 'asking_price'
                temp[label] = this.convertToIntFormat(val)
                break
              case 'Sold_Price':
                label = 'sold_price'
                temp[label] = this.convertToIntFormat(val)
                break
              case 'Sold_Date':
                label = 'sold_date'
                temp[label] = this.getCsvListedDate(val)
                break
              case 'ML_#':
                label = 'mls_id'
                temp[label] = val
                break
              case 'Prop_Type':
                label = 'property_type'
                temp[label] = val
                break
              case 'Public_Remarks':
                label = 'remark'
                temp[label] = val
                break
              case 'S/A':
                label = 'subarea'
                temp[label] = val
                break
              case 'Status':
                label = 'status'
                temp[label] = this.getStatus(val)
                break
              case 'Total_Baths_':
                label = 'bathrooms'
                temp[label] = val
                break
              case 'Total_Bedrooms':
                label = 'bedrooms'
                temp[label] = val
                break
              case 'TypeDwel':
                label = 'dwelling_type'
                temp[label] = this.formatDwelType(val)
                break
              case 'Yr_Blt':
                label = 'year_built'
                temp[label] = val
                break
              case 'Pics':
                label = 'photo_url'
                temp[label] = val
                break
              case 'PicCount':
                label = 'photos_count'
                temp[label] = val
                break
              default:
                break
            }
          })

          temp.style_of_home = ''
          temp.lot_size_sqft = ''
          temp.address_unit = ''
          temp.address_number = ''
          temp.address_street = ''
          temp.address_street_designation_id = ''
          temp.address_direction = ''
          temp.province = ''
          temp.postal_code = ''

          this.csvData.push(temp)
        }
      })

      this.$store.dispatch('superadmin/importCSVListings', { class: 'Residential', data: this.csvData }).then(response => {
        console.log(response)

        if (response.code === 200) {
          this.csvData = []
        }
        // if (response.code === 200) {
        //   this.$emit('on-delete-listing', this.listing)
        //   this.showToast('Listing Added', 'CheckCircleIcon', 'Listings have been added successfully', 'success', 'bottom-right')
        // } else {
        //   this.showToast('Failed', 'AlertCircleIcon', 'There was an error during the update', 'danger', 'bottom-right')
        // }
      }).catch(err => {
        console.error(err)
      })
    },
  },
}
</script>

<style>
</style>
