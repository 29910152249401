<template>
  <div class="component">
    <b-button
      v-if="!isConnectedWithInsta"
      variant="outline-primary"
      @click="test"
    >
      <!-- connectWithInsta -->
      <feather-icon icon="InstagramIcon" />
      Connect with Instagram
    </b-button>

    <b-button
      v-else
      variant="outline-primary"
      class="facebookBtn"
      @click="disconnect"
    >
      <feather-icon icon="FacebookIcon" />
      Disconnect
    </b-button>

    <b-modal
      id="instagram-select-page-modal"
      title="Select Page"
      @ok="handleOk"
    >
      <b-list-group>
        <b-list-group-item
          v-for="(single, index) in instaPageList"
          :key="'single-page-' + index"
          button
          @click="onSinglePageClick(single.id, single.access_token)"
        >
          <p>Page Name: {{ single.name }}</p>
          <!-- <p>Page ID: {{ single.id }}</p> -->
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
  },
  data() {
    return {
      isConnect: false,
      FBUserAccessToken: 'EAAHyMseFslcBAPJ0gCpM2nD0I7zrLb5XORLSv9I5oZAEc0VYrven7HZASYuUFnpS6T1wS0o7KrydSsZBp7xezmRr5LQbzzkZAcsmCP9tknNwtHwlBCut9PGx4mZCSVLGPs5PiQwQ0V7mYwCjC8WS1wNf5f3PV03gfp6YZBMFbtFjTuwT6jArWmB1vZAUBC2qvaksPsTvMpRGokEGL4zlOZBo',
      instaPageList: [],
      selectedPageID: '104575015265619',
      selectedPageToken: 'EAAHyMseFslcBAEKOdAQ6w2Y4ZA2MFXZCDSztmLRvmMrrex0fQjtmMG9juVMSeAmWlnXj0QFDsEeRhdWiguZBaBiO5SCLKsnb8zavpQUpE5SU6tXq3QD9EeAkIOOMwxpKWSuxbhpjw7uYf1i6cE82ZCqz99RqaKcwXZATWCnJXuu51lh54RTSeN3TYIZCbGWjgyrroxF86BoGlWz9kh9Wz1',
      instaUserID: '17841447658198300',
    }
  },
  computed: {
    isConnectedWithInsta() {
      return this.isConnect
    },
  },
  methods: {
    async connectWithInsta() {
      const vm = this

      window.FB.login(
        response => {
          console.log(response)
          if (response.authResponse) {
            vm.FBUserAccessToken = response.authResponse.accessToken
            vm.getPageList()
            this.showToast(
              'Success',
              'AlertCircleIcon',
              'Connected',
              'danger',
              'bottom-right',
            )
          } else {
            this.showToast(
              'Failed',
              'AlertCircleIcon',
              'User cancelled login or did not fully authorize',
              'danger',
              'bottom-right',
            )
          }
        },
        { scope: 'instagram_basic, instagram_content_publish, pages_show_list' },
      )
      return false
    },
    onSinglePageClick(id, token) {
      this.selectedPageID = id
      this.selectedPageToken = token
    },
    getPageList() {
      // get pages
      this.$store.dispatch('social/getInstaPages', this.FBUserAccessToken).then(response => {
        this.instaPageList = response.data.data
        this.$bvModal.show('instagram-select-page-modal')
      }).catch(err => {
        console.error(err)
      })
    },
    test() {
      const exp = new Date()
      exp.setDate(exp.getDate() + 60)

      const opt = {
        type: 'instagram',
        value: {
          user_id: this.instaUserID,
          fb_accsee_token: this.FBUserAccessToken,
        },
        expired_at: exp.toISOString().replace(/T/, ' ').replace(/\..+/, ''),
      }

      const data = {
        account_id: this.$route.params.id,
        options: opt,
      }

      this.$store.dispatch('social/addSocialMedia', data).then(response => {
        console.log(response)
      }).catch(err => {
        console.error(err)
      })
    },
    handleOk() {
      this.$store.dispatch('social/getInstaAccount', { page_id: this.selectedPageID, access_token: this.selectedPageToken })
        .then(response => {
          const exp = new Date()
          exp.setDate(exp.getDate() + 60)

          const opt = {
            type: 'instagram',
            value: {
              user_id: response.instagram_business_account.id,
              fb_accsee_token: this.FBUserAccessToken,
            },
            expired_at: exp,
          }

          console.log(opt)
        }).catch(err => {
          console.error(err)
        })
    },
    disconnect() {
      console.log('disconnect')
    },
    showToast(title, icon, text, variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },

}
</script>

<style>

</style>
