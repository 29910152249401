<template>
  <div class="page">

    <b-tabs
      vertical
      class="editContainer"
      pills
    >
      <!-- ******************************************** User ***************************************** -->
      <b-tab
        title="Account / Users"
      >
        <b-card>
          <h3 class="tabTitle">
            Account Information
          </h3>

          <!-- media -->
          <b-media no-body>
            <b-link>
              <div class="avatarContainer">
                <b-avatar
                  size="lg"
                  :src="imgPreviewUrl"
                />
              </div>
            </b-link>
            <!--/ avatar -->
            <b-media-body class="mt-75 ml-75">
              <!-- upload button -->
              <b-button
                variant="outline-primary"
                size="sm"
                class="mb-75 mr-75"
                @click="$refs.refInputEl.$el.click()"
              >
                Upload
              </b-button>
              <b-form-file
                ref="refInputEl"
                v-model="profileFile"
                accept="image/*"
                :hidden="true"
                plain
                @change="onSelectProfilcePic"
              />
              <!--/ upload button -->

              <!-- reset -->
              <b-button
                variant="outline-secondary"
                size="sm"
                class="mb-75 mr-75"
                @click="onProfilePicResetClick"
              >
                Reset
              </b-button>
              <!--/ reset -->
              <b-card-text>Allowed JPG, GIF or PNG. Max size of 1MB</b-card-text>
            </b-media-body>
          </b-media>
          <!--/ media -->
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <!-- form -->
            <b-form class="mt-2">
              <b-row>
                <b-col sm="6">
                  <b-form-group
                    label="Name"
                    label-for="account-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="account.name"
                        name="name"
                        placeholder="Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    label="Account Type"
                    label-for="accountType"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Account Type"
                      rules="required"
                    >
                      <v-select
                        id="member-type"
                        v-model="account.accountType"
                        :options="accountTypeOptions"
                        name="member-type"
                        :clearable="false"
                        :searchable="false"
                        label="text"
                        :reduce="memberType => memberType.value"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="6">
                  <b-form-group
                    label="Account Plan"
                    label-for="account-plan"
                  >
                    <b-form-input
                      v-model="account.account_plan"
                      name="account-plan"
                      placeholder="Account Plan"
                    />
                  </b-form-group>
                </b-col> -->

                <b-col sm="6">
                  <b-form-group
                    label="E-mail"
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="E-mail"
                      rules="required"
                    >
                      <b-form-input
                        v-model="account.email"
                        name="email"
                        placeholder="E-mail"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    label="Phone"
                    label-for="phone"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Phone"
                      rules="required"
                    >
                      <b-form-input
                        v-model="account.phone"
                        name="phone"
                        placeholder="Phone"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    label="Website"
                    label-for="website"
                  >
                    <b-form-input
                      v-model="account.website"
                      name="website"
                      placeholder="Website"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    label="Office"
                    label-for="office"
                  >
                    <!-- <b-form-input
                      v-model="account.officeName"
                      name="office"
                      placeholder="Office"
                    /> -->

                    <v-select
                      v-model="selectedOffice"
                      :get-option-label="option => option.officeName"
                      :options="officeOptions"
                      :filterable="false"
                      taggable
                      @search="onSearch"
                    >
                      <!-- @input="setOffice" -->
                      <template slot="no-options">
                        <span v-if="didUserType">
                          No results
                        </span>
                        <span v-else>
                          type to search for offices
                        </span>

                      </template>
                      <template
                        v-if="Object.keys(selectedOffice).length > 0"
                        slot="option"
                        slot-scope="officeOptions"
                      >
                        <div class="d-center">
                          <p>{{ officeOptions.officeName }}</p>
                          <p v-if="officeOptions.officeShortName && officeOptions.officePhone">
                            {{ officeOptions.officeShortName }} | {{ officeOptions.officePhone }}
                          </p>
                        </div>
                      </template>
                      <template
                        v-if="Object.keys(selectedOffice).length > 0"
                        slot="selected-option"
                        slot-scope="officeOptions"
                      >
                        <div class="selected d-center">
                          <p>{{ officeOptions.officeName }}</p>
                          <p v-if="Object.keys(officeOptions).length && officeOptions.officeShortName && officeOptions.officePhone">
                            {{ officeOptions.officeShortName }} | {{ officeOptions.officePhone }}
                          </p>
                        </div>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-button
                    variant="outline-primary"
                    class="mt-2 mr-1"
                    :disabled="invalid"
                    @click="onAccountSaveClick"
                  >
                    Save changes
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
        <b-card>
          <h3 class="tabTitle">
            Users
          </h3>
          <b-table
            responsive="sm"
            :items="users"
            :fields="userFields"
          >
            <template #cell(name)="data">
              <span
                style="cursor:pointer;"
                @click="editUser(data)"
              >
                {{ data.value }}
              </span>
            </template>
            <template #cell(payment)="data">
              <!-- {{ data.value === 1 ? 'Yes' : 'No' }} -->
              <div>
                <feather-icon
                  v-if="data.value !== 1"
                  icon="XIcon"
                  size="20"
                  class="redIcon"
                />
                <feather-icon
                  v-else
                  icon="CheckIcon"
                  size="20"
                  class="greenIcon"
                />
              </div>
            </template>
            <template #cell(active)="data">
              <!-- {{ data.value === 1 ? 'Yes' : 'No' }} -->
              <div>
                <feather-icon
                  v-if="data.value !== 1"
                  icon="XIcon"
                  size="20"
                  class="redIcon"
                />
                <feather-icon
                  v-else
                  icon="CheckIcon"
                  size="20"
                  class="greenIcon"
                />
              </div>
            </template>
            <template
              #cell(office)="data"
            >
              <div class="d-center">
                <p>{{ data.value.officeName }}</p>
                <p v-if="data.value.officeShortName && data.value.officePhone">
                  {{ data.value.officeShortName }} | {{ data.value.officePhone }}
                </p>
              </div>
            </template>
            <template #cell(action)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="editUser(data)">
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Detail</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-modal
              v-model="isUserModalShow"
              title="Update User"
              ok-title="Update"
              :ok-disabled="invalid"
              @ok="submitUserUpdate"
            >
              <b-form class="mt-2">
                <b-row>
                  <b-col sm="12">
                    <b-form-group
                      label="Name"
                      label-for="account-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="selectedUser.name"
                          name="name"
                          placeholder="Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group
                      label="Login Name"
                      label-for="login-name"
                    >
                      <b-form-input
                        v-model="selectedUser.loginName"
                        name="login-name"
                        placeholder="Login Name"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group
                      label="Office"
                      label-for="office"
                    >
                      <v-select
                        v-model="selectedUser.office"
                        :get-option-label="option => option.officeName"
                        :options="officeOptions"
                        :filterable="false"
                        taggable
                        @search="onSearch"
                      >
                        <template slot="no-options">
                          <span v-if="didUserType">
                            No results
                          </span>
                          <span v-else>
                            type to search for offices
                          </span>

                        </template>
                        <template
                          v-if="Object.keys(selectedOffice).length > 0"
                          slot="option"
                          slot-scope="officeOptions"
                        >
                          <div class="d-center">
                            <p>{{ officeOptions.officeName }}</p>
                            <p v-if="officeOptions.officeShortName && officeOptions.officePhone">
                              {{ officeOptions.officeShortName }} | {{ officeOptions.officePhone }}
                            </p>
                          </div>
                        </template>
                        <template
                          v-if="Object.keys(selectedOffice).length > 0"
                          slot="selected-option"
                          slot-scope="officeOptions"
                        >
                          <div class="selected d-center">
                            <p>{{ officeOptions.officeName }}</p>
                            <p v-if="Object.keys(officeOptions).length && officeOptions.officeShortName && officeOptions.officePhone">
                              {{ officeOptions.officeShortName }} | {{ officeOptions.officePhone }}
                            </p>
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                    <!-- <b-form-group
                      label="Office"
                      label-for="office"
                    >
                      <b-form-input
                        v-model="selectedUser.office"
                        name="office"
                        placeholder="Office"
                      />
                    </b-form-group> -->
                  </b-col>
                  <b-col sm="12">
                    <b-form-group
                      label="Email"
                      label-for="email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="selectedUser.email"
                          name="email"
                          placeholder="Email"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group
                      label="Phone"
                      label-for="phone"
                    >
                      <b-form-input
                        v-model="selectedUser.phone"
                        name="phone"
                        placeholder="Phone"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group
                      label="Website"
                      label-for="website"
                    >
                      <b-form-input
                        v-model="selectedUser.website"
                        name="website"
                        placeholder="Website"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </b-modal>
          </validation-observer>
        </b-card>
      </b-tab>
      <b-tab
        title="Listings"
      >
        <b-card>
          <ListingList />
        </b-card>
      </b-tab>
      <b-tab
        title="Predefines"
      >
        <b-card>
          <PredefineList />

          <!-- <b-modal
            id="super-add-predefine-modal"
            size="xl"
          >
            <PredefineAddModal />
          </b-modal> -->
        </b-card>
      </b-tab>
      <b-tab
        title="Support"
      >
        <b-card>
          Coming Soon...
        </b-card>
      </b-tab>

      <!-- ********************************** Billing Information ******************************* -->
      <b-tab
        title="Subscription / Billing"
      >
        <b-row>
          <b-col sm="12">
            <b-card>
              <h3 class="tabTitle mb-1">
                Billing Information
              </h3>

              <BillingCardList :list="paymentMethods" />
            </b-card>
          </b-col>
          <b-col sm="12">
            <b-card>
              <h3 class="tabTitle mb-1">
                Subscription Information
              </h3>
              <SubscriptionView
                v-if="!showSubscriptionUpdate"
                :history="currentPlan"
                :option="subscriptionOption"
                @onUpdateClick="showSubscriptionUpdate = true"
              />
              <SubscriptionForm
                v-else
                :history="currentPlan"
                :account-type="account.type"
                :coupons="coupons"
                @onCancelClick="showSubscriptionUpdate = false"
              />
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab
        title="Social Media Integration"
      >
        <b-card>
          <b-row>
            <b-col sm="12">
              <b-tabs>
                <b-tab title="Facebook">
                  <FBButton />
                  <!-- <b-button variant="outline-primary" >Connect with Facebook</b-button> -->
                </b-tab>
                <b-tab title="Instagram">
                  <!-- <b-button variant="outline-primary" >Connect with Instagram</b-button> -->
                  <InstaButton />
                </b-tab>
                <b-tab title="LinkedIn">
                  <b-button variant="outline-primary" >Connect with LinkedIn</b-button>
                </b-tab>
                <b-tab title="Twitter">
                  <b-button variant="outline-primary" >Connect with Twitter</b-button>
                </b-tab>
              </b-tabs>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard, BButton, BFormGroup, BFormInput, BTabs, BTable, BDropdown, BDropdownItem,
  BTab, BCardText, BForm, BRow, BCol, BMedia, BLink, BMediaBody, BFormFile, BAvatar, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import BillingCardList from '../../components/Billing/BillingCardList.vue'
import SubscriptionView from '../../components/Subscription/SubscriptionView.vue'
import SubscriptionForm from '../../components/Subscription/SubscriptionForm.vue'

import PredefineList from './components/Predefines/List.vue'
import ListingList from './components/Listings/List.vue'

import FBButton from '../../components/Social/FBButton.vue'
import InstaButton from '../../components/Social/InstaButton.vue'

// import PredefineAddModal from '../../Predefines/Create.vue'

// extend('password', {
//   params: ['target'],
//   validate(value, { target }) {
//     return value === target
//   },
//   message: 'Password confirmation does not match',
// })

export default {
  components: {
    BCard,
    BButton,
    BFormGroup,
    BFormInput,
    BTabs,
    BTab,
    BCardText,
    BForm,
    BRow,
    BCol,
    BMedia,
    BLink,
    BMediaBody,
    BFormFile,
    BAvatar,
    BTable,
    BDropdown,
    BDropdownItem,
    BModal,
    ValidationObserver,
    ValidationProvider,
    BillingCardList,
    SubscriptionView,
    SubscriptionForm,
    PredefineList,
    ListingList,
    FBButton,
    InstaButton,
    // PredefineAddModal,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,
      // new data
      isUserModalShow: false,
      profileFile: [],
      account: {
        name: '',
        accountPlan: '',
        email: '',
        phone: '',
        website: '',
        office: '',
        accountType: '',
        profile_image: '',
        type: '',
      },
      userFields: ['name', 'loginName', 'office', 'email', 'phone', 'website', 'action'],
      users: [],
      selectedUser: {},
      billingMockUp: {},
      subscriptionMockup: {
        created_at: '2021-05-26 11:29:22',
        ends_at: '2021-06-02 10:31:53',
        id: 3,
        name: 'default',
        quantity: 1,
        stripe_id: 'sub_JYYbeJXXrMkzo5',
        stripe_plan: 'price_1IIeAHDqGqXm4u42ZYGZBmrH',
        stripe_status: 'canceled',
        trial_ends_at: null,
        updated_at: '2021-06-02 10:31:53',
        user_account_id: 25,
      },
      paymentMethods: [],
      currentPlan: [],
      showSubscriptionUpdate: false,
      imgPreviewUrl: '',
      accountTypeOptions: [
        { value: 'agent', text: 'Agent' },
        { value: 'team', text: 'Team' },
        { value: 'brokerage', text: 'Brokerage' },
      ],
      officeOptions: [],
      didUserType: false,
      selectedOffice: {},
      selectedUserOffice: {},
      subscriptionOption: {},
      coupons: [],
    }
  },
  computed: {
    getMaxFileSize() {
      return this.$store.state.MAX_FILE_UPLOAD_SIZE
    },
  },
  created() {
    this.$store.dispatch('social/getSocialMediaList', { account_id: this.$route.params.id }).then(response => {
      console.log('get social media', response)
    }).catch(err => {
      console.error(err)
    })

    this.$store.dispatch('superadmin/getAccount', { id: this.$route.params.id }).then(response => {
      console.log(response)
      if (response.code === 200) {
        // Account
        this.account = {
          id: response.data.account.id,
          name: response.data.account.name,
          email: response.data.account.primary_email,
          phone: response.data.account.primary_phone,
          website: response.data.account.website,
          office: response.data.account.office,
          profile_image: '',
          accountType: response.data.account.account_type,
          type: response.data.account.account_type,
        }

        this.selectedOffice = response.data.account.office
        this.imgPreviewUrl = response.data.account.profile_image

        // Billing, subscription
        this.paymentMethods = response.data.subscription.paymentMethod ? response.data.subscription.paymentMethod : []
        this.currentPlan = response.data.subscription.currentPlan ? response.data.subscription.currentPlan : []
        this.coupons = response.data.subscription.availableCoupons ? response.data.subscription.availableCoupons.data : []
        this.getSuitablePlan()

        // Users
        this.users = response.data.users.map(r => {
          return {
            id: r.id,
            name: r.name,
            loginName: r.agent_mls_loginName,
            office: r.office,
            email: r.email,
            phone: r.phone,
            website: r.website,
          }
        })
      }
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
    getSuitablePlan() {
      this.$store.dispatch('billings/getSuitablePlan').then(response => {
        console.log(response)
        this.subscriptionOption = response.data
      }).catch(err => {
        console.error(err)
      })
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    editUser(data) {
      this.isUserModalShow = true
      this.selectedUser = JSON.parse(JSON.stringify(data.item))
    },
    onSearch(search, loading) {
      if (search && search.trim().length > 1) {
        loading(true)
        this.getOfficeOptions(search, loading)
        this.didUserType = true
      } else {
        this.officeOptions = []
        loading(false)
        this.didUserType = false
      }
    },
    getOfficeOptions(search, loading) {
      this.$store.dispatch('offices/getOfficeNames', { key: search }).then(response => {
        if (response.code === 200) {
          this.officeOptions = response.data
          loading(false)
        }
      }).catch(err => {
        console.error(err)
      })
    },
    submitUserUpdate() {
      if (Object.keys(this.selectedUser.office).length) {
        this.selectedUser.officeId = this.selectedUser.office.officeID.toString()
      } else {
        this.selectedUser.officeId = ''
      }

      this.$store.dispatch('superadmin/updateUser', this.selectedUser).then(response => {
        if (response.code === 200) {
          const targetIndex = this.users.findIndex(i => i.id === response.data.id)
          this.users[targetIndex].id = response.data.id
          this.users[targetIndex].name = response.data.name
          this.users[targetIndex].loginName = response.data.agent_mls_loginName
          this.users[targetIndex].office = response.data.office
          this.users[targetIndex].email = response.data.email
          this.users[targetIndex].phone = response.data.phone
          this.users[targetIndex].website = response.data.website

          this.showToast('Success', 'CheckCircleIcon', 'User ' + response.data.name + ' has been added successfully', 'success', 'bottom-right')
        }
      }).catch(err => {
        console.error(err)
      })
    },
    onAccountSaveClick() {
      const formData = new FormData()

      formData.append('id', this.account.id)
      formData.append('name', this.account.name)
      formData.append('accountType', this.account.accountType)
      formData.append('email', this.account.email)
      formData.append('phone', this.account.phone)
      formData.append('website', this.account.website)
      formData.append('officeId', this.selectedOffice.officeID ? this.selectedOffice.officeID : '')

      if (this.account.profile_image !== null && this.account.profile_image !== '') {
        formData.append('profile_image', this.account.profile_image)
      }

      this.$store.dispatch('superadmin/updateAccount', formData).then(response => {
        if (response.code === 200) {
          this.showToast('Success', 'CheckCircleIcon', 'Account has been added successfully', 'success', 'bottom-right')
        } else {
          this.showToast('Failed', 'AlertCircleIcon', 'There was an error during the update', 'danger', 'bottom-right')
        }
      }).catch(err => {
        console.error(err)
      })
    },
    onSelectProfilcePic(e) {
      const image = e.target.files[0]

      if (image.size < 1024 * this.getMaxFileSize) {
        const reader = new FileReader()

        reader.readAsDataURL(image)

        reader.onload = event => {
          this.imgPreviewUrl = event.target.result
          this.account.profile_image = image
        }
      } else {
        this.showToast('Upload Failed', 'AlertCircleIcon', 'File exceeds the maximum file size (1MB)', 'danger', 'bottom-right')
      }
    },
    onProfilePicResetClick() {
      this.account.profile_image = ''
      this.imgPreviewUrl = ''
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
    getRecordStatusVariant(status) {
      switch (status) {
        case 'completed': return 'success'
        default: return 'dark'
      }
    },
  },
}

</script>

<style lang="scss">
.avatarContainer {
  padding:20px 10px 20px 20px;
}

.recordTable {
  text-transform: capitalize;
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
