<template>
  <div class="component">
    <b-button
      v-b-modal.super-add-predefine-modal
      variant="outline-primary"
      class="mb-2"
    >
      <!-- @click="$router.push({name: 'create-predefine'})" -->
      <feather-icon icon="PlusIcon" />
      Add New Predefine
    </b-button>

    <b-table
      v-if="getPredefineRows"
      responsive="sm"
      :fields="predefineFields"
      :items="predefines"
      :per-page="predefinePerPage"
      :current-page="predefineCurrentPage"
    >
      <template #cell(id)="data">
        <div
          style="color: #7367f0; cursor: pointer;"
          @click="onSinglePredefineClick(data.item.id)"
        >
          {{ data.value }}
        </div>
      </template>

      <!-- <template #cell(shortcode)="data">
              <span class="d-flex">{{ data.value }}</span>
              <span
                v-b-modal.view-shortcode
                style="text-decoration: underline;"
                @click="onViewShortCodeClick(data.value)"
              >View</span>
            </template> -->

      <template #cell(action)="data">
        <b-dropdown
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template v-slot:button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="text-body align-middle mr-25"
            />
          </template>
          <b-dropdown-item @click="onSinglePredefineClick(data.item.id)">
            <feather-icon
              icon="Edit2Icon"
              class="mr-50"
            />
            <span>Edit</span>
          </b-dropdown-item>

          <b-dropdown-item
            @click="onPredefineRemoveClick(data.item)"
          >
            <feather-icon
              icon="Trash2Icon"
              class="mr-50"
            />
            <span>Remove</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-pagination
      v-if="getPredefineRows"
      v-model="predefineCurrentPage"
      class="d-flex justify-content-center"
      :total-rows="getPredefineRows"
      :per-page="predefinePerPage"
      aria-controls="my-table"
    />

    <b-modal
      id="super-add-predefine-modal"
      size="xl"
      centered
      hide-footer
    >
      <PredefineAddModal @add-predefine="addPredefine" />
    </b-modal>

    <b-modal
      id="super-edit-predefine-modal"
      size="xl"
      centered
      hide-footer
    >
      <PredefineEditModal :single-predefine="getSinglePredefine" />
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BTable, BPagination, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PredefineAddModal from '../../../../Predefines/Create.vue'
import PredefineEditModal from '../../../../Predefines/Edit.vue'

export default {
  components: {
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    PredefineAddModal,
    PredefineEditModal,
  },
  data() {
    return {
      predefines: [],
      singlePredefine: {},
      predefineFields: ['id', 'name', { key: 'predefine_type', sortable: true }, 'shortcode', 'action'],
      predefinePerPage: 5,
      predefineCurrentPage: 1,
    }
  },
  computed: {
    getPredefineRows() {
      return this.predefines.length
    },
    getSinglePredefine() {
      return this.singlePredefine
    },
  },
  created() {
    this.$store.dispatch('superadmin/getPredefine', { id: this.$route.params.id }).then(response => {
      this.predefines = response.data
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
    onSinglePredefineClick(id) {
      const data = {
        account_id: this.$route.params.id,
        predefine_id: id,
      }

      this.$store.dispatch('superadmin/getSinglePredefine', data).then(response => {
        this.singlePredefine = response.data
        this.$bvModal.show('super-edit-predefine-modal')
      }).catch(err => {
        console.error(err)
      })
    },
    onPredefineRemoveClick(item) {
      const removeData = {
        account_id: this.$route.params.id,
        predefine_id: item.id,
      }
      this.$store.dispatch('superadmin/deletePredefine', removeData).then(response => {
        if (response.code === 200) {
          this.showToast('Predefine Deleted', 'CheckCircleIcon', 'Predefine has been deleted successfully', 'success', 'bottom-right')
          this.predefines = this.predefines.filter(data => data.id !== null && data.id !== response.data.id)
        } else {
          this.showToast('Failed', 'CheckCircleIcon', 'There was an error during the update', 'danger', 'bottom-right')
        }
      }).catch(err => {
        console.error(err)
      })
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
    addPredefine(data) {
      this.predefines.push(data)
    },
  },
}
</script>

<style>

</style>
