<template>
  <div class="page">
    <validation-observer
      ref="registerForm"
      #default="{invalid}"
    >
      <form @submit.prevent="submit">
        <b-card>
          <div class="row">
            <div class="col-6">
              <b-form-group
                label="Name"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="name"
                      v-model="predefineName"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="name"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group
                label="Predefine Type"
                label-for="predefine-type"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Predefine Type"
                >
                  <v-select
                    v-model="predefineType"
                    :options="['Area', 'Featured', 'School', 'Polygon', 'Address']"
                    name="method"
                    :clearable="false"
                    :searchable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
        </b-card>
        <b-card :title="data.predefineType">
          <AreaForm
            v-if="predefineType ==='Area'"
            :data="data"
            :options="options"
          />
          <FeaturedForm
            v-if="predefineType === 'Featured'"
            :data="data"
          />
          <SchoolForm
            v-if="predefineType === 'School'"
            :data="data"
            :options="options"
          />
          <AddressForm
            v-if="predefineType === 'Address'"
            :data="data"
          />
          <PolygonForm
            v-if="predefineType === 'Polygon'"
            :data="data"
          />
        </b-card>
        <GeneralForm
          :data="data"
          :options="options"
          :predefine-type="predefineType"
          :type-options="typeOptions"
        />

        <div class="row">
          <div class="col-12 d-flex justify-content-end">
            <b-button
              v-if="getClient.memberType === 'superuser'"
              class="mr-1"
              variant="outline-danger"
              @click="$bvModal.hide('super-edit-predefine-modal')"
            >
              Cancel
            </b-button>
            <b-button
              variant="outline-primary"
              type="submit"
              :disabled="invalid"
              @submit.prevent="submit"
            >
              Save
            </b-button>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard, BFormGroup, BButton, BInputGroup, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import AreaForm from './components/AreaForm.vue'
import GeneralForm from './components/GeneralForm.vue'
import FeaturedForm from './components/FeaturedForm.vue'
import AddressForm from './components/AddressForm.vue'
import PolygonForm from './components/PolygonForm.vue'
import SchoolForm from './components/SchoolForm.vue'

export default {
  components: {
    BCard, vSelect, BFormGroup, ValidationProvider, ValidationObserver, BButton, BInputGroup, BFormInput, AreaForm, FeaturedForm, AddressForm, PolygonForm, GeneralForm, SchoolForm,
  },
  props: {
    singlePredefine: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      options: {},
      predefineName: '',
      targetID: '',
      predefineType: '',
      data: {
        class: [],
        general: {
          residential: {
            class: [],
            status: [],
            priceFrom: '',
            priceTo: '',
            propertyType: '',
            type: [],
          },
          commercial: {
            class: ['CM_1'],
            status: [],
            priceFrom: '',
            priceTo: '',
            propertyType: '',
            type: [],
          },
          rental: {
            class: ['RT_5'],
            status: [],
            priceFrom: '',
            priceTo: '',
            propertyType: '',
            dwellingType: '',
            type: [],
          },
        },
        areas: null,
        schools: '',
        featured: 'Featured',
        polygon: {
          lat: {
            min: '',
            max: '',
          },
          lng: {
            min: '',
            max: '',
          },
          path: [],
        },
      },
      typeOptions: {
        RD_1: [],
        RA_2: [],
        LD_4: [],
        CM_1: [],
        RT_5: [],
        MF_3: [],
      },
    }
  },
  computed: {
    getClient() {
      return this.$store.state.clientInfo
    },
  },
  watch: {
    singlePredefine: {
      immediate: true,
      deep: true,
      handler(val) {
        // this.options = val
        this.targetID = val.id
        this.predefineType = val.predefine_type
        this.predefineName = val.name
        this.data = val.options
      },
    },
  },
  created() {
    this.$store.dispatch('predefines/getOptions').then(response => {
      if (response.code === 200) {
        this.options = response.data

        this.options.type.forEach(opt => {
          this.typeOptions[opt.class].push(opt)
        })
      }
    }).catch(err => {
      console.error(err)
    })

    if (Object.keys(this.getClient) && this.getClient.memberType !== 'superuser') {
      const { id } = this.$route.params

      this.$store.dispatch('predefines/getSinglePredefine', id).then(response => {
        this.targetID = response.data.id
        this.predefineType = response.data.predefine_type
        this.predefineName = response.data.name
        this.data = response.data.options
      }).catch(err => {
        console.error(err)
      })
    }
  },
  methods: {
    submit() {
      if (this.data.general.listing.residential.propertyType !== null) {
        this.data.general.listing.residential.class = []
        this.data.general.listing.residential.propertyType.forEach(element => {
          let className = ''
          switch (element) {
            case 'Residential Detached': className = 'RD_1'; break
            case 'Residential Attached': className = 'RA_2'; break
            case 'Land Only': className = 'LD_4'; break
            case 'Multifamily': className = 'MF_3'; break
            default: className = 'RD_1'; break
          }

          this.data.general.listing.residential.class.push(className)
        })
      }
      this.data.general.listing.commercial.class = ['CM_1']
      this.data.general.listing.rental.class = ['RT_5']

      const data = {
        id: this.targetID,
        name: this.predefineName,
        predefine_type: this.predefineType,
        options: this.data,
      }

      if (Object.keys(this.getClient) && this.getClient.memberType !== 'superuser') {
        this.$store.dispatch('predefines/updatePredefine', data).then(response => {
          if (response.code === 200) {
            this.showToast('Predefine Updated', 'CheckCircleIcon', 'Predefine has been updated successfully', 'success', 'bottom-right')
          } else {
            this.showToast('Failed', 'CheckCircleIcon', 'There was an error during the update', 'danger', 'bottom-right')
          }
        }).catch(err => {
          console.error(err)
        })
      } else if (Object.keys(this.getClient) && this.getClient.memberType === 'superuser') {
        const superPredefineData = {
          account_id: this.$route.params.id,
          predefine_id: this.targetID,
          options: data,
        }

        this.$store.dispatch('superadmin/updatePredefine', superPredefineData).then(response => {
          if (response.code === 200) {
            this.showToast('Predefine Updated', 'CheckCircleIcon', 'Predefine has been updated successfully', 'success', 'bottom-right')
          } else {
            this.showToast('Failed', 'CheckCircleIcon', 'There was an error during the update', 'danger', 'bottom-right')
          }
        }).catch(err => {
          console.error(err)
        })
      }
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
