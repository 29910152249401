<template>
  <div class="listingCardContainer">
    <b-card>
      <div class="flex-justify-between mb-1">
        <b-card-text style="font-size:14px;">
          <b-form-checkbox
            v-if="isShowCheckBox()"
            v-model="selected"
            :value="listing.mls_id"
            class="custom-control-primary"
            @change="onDeleteCheckboxChanged"
          >
            <b>{{ listing.mls_id }}</b>
          </b-form-checkbox>
          <b v-else>{{ listing.mls_id }}</b>
        </b-card-text>
        <b-card-text style="font-size:14px;">
          Listed Date: {{ listing.list_date }}
        </b-card-text>
      </div>
      <div class="listingContainer">
        <div class="listingImageContainer">
          <div
            class="listingImage"
            :style="listing.photo_url ? 'background-image:url('+ listing.photo_url + ')' : 'background: url(' + require('@/assets/images/ComingSoon-detail.jpg') + ');'"
          />
          <div
            class="listingImage default"
            :style="'background: url(' + require('@/assets/images/ComingSoon-detail.jpg') + ');'"
          />
        </div>
        <div class="listingDetailContainer">
          <b-card-text>{{ listing.source }}</b-card-text>
          <b-card-text style="text-transform: uppercase;">
            {{ listing.address }}
          </b-card-text>
          <b-card-text style="text-transform: uppercase;">
            {{ listing.city }}, {{ listing.postal_code }}
          </b-card-text>
          <b-card-text
            :class="listing.status.toLowerCase()"
            class="mt-1 "
          >
            <span class="listingStatus pr-1">{{ listing.status.toLowerCase() }}</span><span class="listingPrice">${{ formatPrice(listing.asking_price) }}</span>
          </b-card-text>
        </div>
        <div
          class="btnsContainer"
        >
          <b-button
            variant="outline-primary"
            class="btn-icon mb-1"
            :to="$route.params.id ? '/' + path + '/' + listing.system_id + '/' + $route.params.id : '/' + path + '/' + listing.system_id "
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
          <!-- listing.source !== 'RETS' -->
          <!-- <b-button
            v-if="isShowDeleteBtn()"
            v-b-modal="'delete-listing-modal-' + listing.system_id"
            variant="outline-danger"
            class="btn-icon"
          >
            <feather-icon icon="TrashIcon" />
          </b-button> -->
        </div>
      </div>
      <div class="clear-both" />
    </b-card>

    <b-modal
      :id="'delete-listing-modal-' + listing.system_id"
      title="Delete listing"
      centered
      cancel-variant="outline-danger"
      ok-variant="outline-primary"
      @ok="confirmDelete"
    >
      <p>
        Delete <b>{{ listing.address }}</b>?
      </p>
    </b-modal>

  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BCard, BButton, BCardText, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BFormCheckbox,
    BCard,
    BCardText,
  },
  props: {
    listing: Object,
    path: String,
  },
  data() {
    return {
      selected: [],
    }
  },
  watch: {
    listing: {
      deep: true,
      handler(val) {
        this.$emit('update-data', val)
      },
    },
  },
  methods: {
    isShowCheckBox() {
      if (this.listing.status === 'Active' && this.listing.source === 'RETS') {
        return false
      }

      return true
    },
    isShowDeleteBtn() {
      if (this.listing.source !== 'RETS') {
        return true
      }

      if (this.listing.status !== 'Active') {
        return true
      }

      return false
    },
    onDeleteCheckboxChanged() {
      const data = {
        system_id: this.listing.system_id,
        type: this.listing.source,
      }

      if (this.selected.length) {
        data.isSelected = true
      } else {
        data.isSelected = false
      }

      this.$emit('on-checkbox-update', data)
    },
    confirmDelete() {
      const listingClass = this.listing.class
      const target = [{ system_id: this.listing.system_id, type: this.listing.source }]
      if (listingClass === 'RD_1' || listingClass === 'RA_2' || listingClass === 'LD_4' || listingClass === 'MF_3') {
        // ********************************************** Residential ********************************************** //
        this.$store.dispatch('listings/deleteResidential', { listings: target }).then(response => {
          if (response.code === 200) {
            if (response.data.completed && response.data.completed.length) {
              this.$emit('on-delete-listing', this.listing)
              this.showToast('Listing Deleted', 'CheckCircleIcon', 'Listing has been deleted successfully', 'success', 'bottom-right')
            }

            if (response.data.failed && response.data.failed.length) {
              const failed = response.data.failed[0]
              this.showToast('Failed', 'AlertCircleIcon', 'Listing ' + failed.listing + ' - ' + failed.message, 'danger', 'bottom-right')
            }
          } else {
            this.showToast('Failed', 'AlertCircleIcon', 'There was an error during the update', 'danger', 'bottom-right')
          }
        }).catch(err => {
          console.error(err)
        })
      } else if (listingClass === 'CM_1') {
        // ********************************************** Commercial ********************************************** //
        this.$store.dispatch('listings/deleteCommercial', { listings: target }).then(response => {
          if (response.code === 200) {
            if (response.data.completed.length) {
              this.$emit('on-delete-listing', this.listing)
              this.showToast('Listing Deleted', 'CheckCircleIcon', 'Listing has been deleted successfully', 'success', 'bottom-right')
            }

            if (response.data.failed && response.data.failed.length) {
              const failed = response.data.failed[0]
              this.showToast('Failed', 'AlertCircleIcon', 'Listing ' + failed.listing + ' - ' + failed.message, 'danger', 'bottom-right')
            }
          } else {
            this.showToast('Failed', 'AlertCircleIcon', 'There was an error during the update', 'danger', 'bottom-right')
          }
        }).catch(err => {
          console.error(err)
        })
      } else if (listingClass === 'RT_5') {
        // ********************************************** Rental ********************************************** //
        this.$store.dispatch('listings/deleteRental', { listings: target }).then(response => {
          if (response.code === 200) {
            if (response.data.completed.length) {
              this.$emit('on-delete-listing', this.listing)
              this.showToast('Listing Deleted', 'CheckCircleIcon', 'Listing has been deleted successfully', 'success', 'bottom-right')
            }

            if (response.data.failed && response.data.failed.length) {
              const failed = response.data.failed[0]
              this.showToast('Failed', 'AlertCircleIcon', 'Listing ' + failed.listing + ' - ' + failed.message, 'danger', 'bottom-right')
            }
          }
        }).catch(err => {
          console.error(err)
        })
      }
    },
    formatPrice(price) {
      if (price !== null) {
        return price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
      }

      return null
    },
    formatDate(date) {
      return (new Date(date).toJSON().slice(0, 10).split('-')
        .join('-'))
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>
<style scoped>
.listingCardContainer{
    width:100%;
}
.listingImageContainer{
    width:150px;
    height:150px;
    position:relative;
    float:left;
    border-radius:5px;
    overflow:hidden;

}
 .listingImage{
     background-size:cover !important;
     background-position:center !important;
     position:absolute;
     top:0;
     left:0;
     width:100%;
     height:100%;
     z-index: 2;
 }

 .listingImage.default {
     z-index: 1;
 }

 .listingDetailContainer{
     width:calc(100% - 150px - 37px);
     padding-left:15px;
    float:left;
 }
 .listingDetailContainer p{
     font-size:13px;
 }

 .listingStatus {
   text-transform: capitalize;
 }
 .listingStatus, .listingPrice{
     font-weight:700;

 }
 .listingDetailContainer .active .listingStatus{
     color:rgb(28, 125, 233);
 }
 .listingDetailContainer .sold .listingStatus{
     color:#ff0000;
 }

 .listingDetailContainer .expired .listingStatus{
     color:#ff0000;
 }

 .listingDetailContainer .terminated .listingStatus{
     color:#ff0000;
 }

 .listingCardContainer .card{
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
 }
 .btnsContainer{
   width:37px;
   float:left;
 }
</style>
